angular.module('fingerink')
    .service('addCardModal', function ($uibModal) {
        this.modalInstance = null;
        this.openModal = function () {
            this.modalInstance = $uibModal.open({
                backdrop: 'static',
                templateUrl: 'modals/addCardModal.tpl.html',
                controller: 'addCardModalCtrl',
                controllerAs: 'controller',
                animation: false,
                size: 'lg'
            });

            return this.modalInstance;
        };

    })
    .controller('addCardModalCtrl', function($rootScope, $scope, session, $stateParams, $state, $window, $uibModalInstance, payTPVService, $sce, $timeout, swalService) {

        var that = this;

        function checkIfFinished() {
            payTPVService.isTarjetaLoaded(that.cardTransaction.transaction).then(function (response) {
                if (response.data == "CORRECT") {
                    $uibModalInstance.close(true);
                } else if (response.data == "ERROR") {
                    swalService.basicSwal("Error añadiendo tarjeta","Esta tarjeta no se ha podido añadir. Por favor, inténtelo de nuevo más adelante", "error");
                    userService.getIframeUrlTarjeta(session.get().user.company.id).then(function (response) {
                        that.register.cardTransaction = response.data;
                        that.url = $sce.trustAsResourceUrl(response.data.url);
                    });
                } else {
                    if (!that.destroy) {
                        that.timer = $timeout(checkIfFinished, 500);
                    }
                }
            });
        }


        payTPVService.getIframeUrlTarjeta(session.get().user.company.id).then(function (response) {
            that.cardTransaction = response.data;
            that.url = $sce.trustAsResourceUrl(response.data.url);
        });


        $scope.$on("$destroy", function () {
            that.destroy = true;
            if (that.timer) {
                $timeout.cancel(that.timer);
            }
        });

        that.close = function () {
            $uibModalInstance.close(false);
        };


        function receiveMessage(event) {
            if (event.origin == "https://app.fingerink.biz") {
                if (event.data == 'cardOk') {
                    checkIfFinished();
                }
                if (event.data == 'cardKo') {
                    swalService.basicSwal("Error añadiendo tarjeta", "Esta tarjeta no se ha podido añadir. Por favor, inténtelo de nuevo más adelante", "error");
                    userService.getIframeUrlTarjeta(session.get().user.company.id).then(function (response) {
                        that.register.cardTransaction = response.data;
                        that.url = $sce.trustAsResourceUrl(response.data.url);
                    });
                }
            } else {
                return;
            }
        }
        window.addEventListener("message", receiveMessage, false);
    });
